@import "../abstracts/variables";

.s-1 {
  .d-none-mobile,
  .sub-navbar-line {
    @media (max-width: $tablet-width) {
      display: none;
    }
  }

  .sub-navbar-line {
    height: 4px;
    background-color: #966c20;
    width: 100%;
  }

  .h-22 {
    display: none;
  }

  .middle-navbar {
    .logo {
      img {
        @media (max-width: $tablet-width) {
          width: 140px;
        }
        @media (max-width: $mobile-width) {
          width: 86px;
          padding-top: 12px;
          padding-bottom: 8px;
        }
      }
    }

    .input-search-mobile {
      padding: 0 15px 15px 15px;
      margin-top: 12px;
      display: none;

      .row-down {
        display: block;
        margin-top: 0;
        width: 100%;
      }

      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (min-width: $small-width) {
          display: none;
        }

        #close-search-mobile {
          width: 50px;
          text-align: right;
        }
      }
    }

    .grid {
      grid-template-columns: 1fr 1fr;
      @media (max-width: $tablet-width) {
        grid-template-columns: 1fr 2fr;
      }
      @media (max-width: $tablet-width) {
        grid-template-columns: 2fr 3fr;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      @media (max-width: $mobile-width) {
        grid-template-columns: 1fr 2fr;
      }
    }

    .row-up {
      display: grid;
      grid-gap: 18px;
      align-content: center;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      @media (max-width: $large-width) {
        grid-gap: 8px;
      }
      @media (max-width: $small-width) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }

      a {
        color: white;

        &:hover {
          text-decoration: none;
        }

        @media (max-width: $tablet-width) {
          display: none;
        }
      }

      .search-none {
        @media (min-width: $small-width) {
          display: none !important;
        }
      }
    }

    .menu {
      padding-top: 16px;
      @media (min-width: $tablet-width) {
        display: none !important;
      }
      @media (max-width: $small-width) and (min-width: $mobile-width) {
        padding-top: 19px;
      }
    }

    .nav-item {
      border: solid 2px #ac8e56;
      border-radius: 3px;
      background: #272727;
      color: #ebeff2;
      font-size: 11px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      line-height: 1.64;
      cursor: pointer;
      font-weight: 600;
      @media (max-width: $tablet-width) {
        height: 33px;
        width: 33px;
      }
      @media (max-width: $small-width) {
        width: 27px;
        height: 27px;
      }

      &:hover {
        background-color: #ac8e56;
        color: $blackGrey;
        transition: all ease .3s;
      }
    }

    .flex {
      @media (max-width: $tablet-width) {
        display: flex;
        justify-content: space-between;
      }
    }

    .row-down {
      @media(max-width: $tablet-width) {
        display: none;
      }
      margin-top: 12px;
      position: relative;

      input {
        height: 25px;
        background-color: #3b3b3b;
        border: none;
        width: 100%;
        color: white;
        padding: 7px 10px;
        font-size: 12px;
        border-radius: 3px;

        &:focus {
          opacity: .9;
          outline: none;
          background: #bfbfbf;
          color: $blackGrey;
        }

        &::placeholder {
          color: $white;
        }

        &::-ms-input-placeholder {
          color: $white;
        }

        &::-ms-input-placeholder {
          color: $white;
        }
      }

      i {
        position: absolute;
        color: #fff;
        right: 9px;
        top: 5px;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  #menu-mobile {
    display: none;
    z-index: 50;
    padding-top: 10px;
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    color: #ac8e56;
    right: 0;
    background: #ac8e56;

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;

      li {
        padding: 1px;
      }

      a {
        color: $blackGrey;
        font-size: 14px;
        font-weight: 600;
      }
    }

    .close {
      position: absolute;
      padding: 5px;
      right: 15px;

      i {
        color: #fff;
      }
    }
  }
}

.vg-nav-sidebar {
  @media (max-width: $large-width) {
    a {
      color: $black-title !important;
    }
  }

}

.vg-nav-main-container {
  a {
    &:hover {
      color: $brownLight !important;
    }
  }

  .font-13 {
    font-size: 13px !important;
  }

  .dropdown-mega-container {
    background: $brownLight !important;
    @media (max-width: $large-width) {
      background: white!important;
    }
  }

  .inside-menu {
    a {
      color: $white;

      &:hover {
        color: $black-title !important;
      }
    }
  }

  .show {
    background: $brownLight;
    @media (max-width: $large-width) {
      background: white!important;
    }

    &:hover {
      .hover {
        color: $black-title !important;
      }
    }
  }

}
.vg-nav>ul>li.active a,
.vg-nav>ul>li a:hover {
  span{
    .default{
      color: $brownLight;
    }
  }

}
.navigation {
  a{
    padding: 10px!important;
  }
}
