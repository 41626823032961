.subscribe {
    .d-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        padding-top: 15px;
        padding-bottom: 18px;
        @media(max-width: $small-width) {
            grid-template-columns: 1fr;
        }
        .logo-trust {
            text-align: right;
            margin-right: 11px;
            @media(max-width: $small-width) {
                text-align: left;
                margin-top: 10px;
            }
        }
        .subscribe-input {
            input {
                height: 30px;
                border-radius: 3px;
                background-color: #ffffff;
                font-size: 13px;
                line-height: 1.54;
                font-weight: 600;
                color: #5c5c5c;
                border: none;
                width: 100%;
                padding-left: 16px;
                max-width: 340px;
                margin-right: 10px;
            }
            button {
                width: 140px;
                height: 30px;
                border-radius: 2.9px;
                background-color: #272727;
                font-size: 13px;
                font-weight: 600;
                line-height: 1.54;
                color: #ffffff;
                border: none;
            }
        }
    }
}