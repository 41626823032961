@import "../abstracts/variables";
@import "grid";
.container {
  max-width: $extra-large-width;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  border: none;
}

.pointer {
  cursor: pointer;
}

.d-grid {
  display: grid;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.start-xs {
  justify-content: flex-start !important;
  text-align: start
}

.center-xs {
  justify-content: center;
  text-align: center
}

.end-xs {
  justify-content: flex-end;
  text-align: end
}

.top-xs {
  align-items: flex-start
}

.middle-xs {
  align-items: center
}

.bottom-xs {
  align-items: flex-end
}

.around-xs {
  justify-content: space-around
}

.between-xs {
  justify-content: space-between
}

.evenly-xs {
  justify-content: space-evenly;
}

// text color
.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

// background color
.bg-white {
  background: #fff !important;
}

.bg-black {
  background: #000;
}

// text alignment
.text-justify {
  text-align: justify !important
}

.text-wrap {
  white-space: normal !important
}

.text-nowrap {
  white-space: nowrap !important
}

.text-left {
  text-align: left !important
}

.text-right {
  text-align: right !important
}

.text-center {
  text-align: center !important
}

// font
.lowercase {
  text-transform: lowercase !important
}

.uppercase {
  text-transform: uppercase !important
}

.capitalize {
  text-transform: capitalize !important
}

.f-light {
  font-weight: 300 !important
}

.f-lighter {
  font-weight: lighter !important
}

.f-normal {
  font-weight: 400 !important
}

.f-semibold {
  font-weight: 500;
}

.bold {
  font-weight: 700 !important
}

.bolder {
  font-weight: bolder !important
}

.f-italic {
  font-style: italic !important
}

// overflow
.o-hidden {
  overflow: hidden;
}

// width
.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

// border
.border {
  border: 1px solid #dee2e6 !important
}

.border-top {
  border-top: 1px solid #dee2e6 !important
}

.border-right {
  border-right: 1px solid #dee2e6 !important
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important
}

.border-left {
  border-left: 1px solid #dee2e6 !important
}

.border-0 {
  border: 0 !important
}

.border-top-0 {
  border-top: 0 !important
}

.border-right-0 {
  border-right: 0 !important
}

.border-bottom-0 {
  border-bottom: 0 !important
}

.border-left-0 {
  border-left: 0 !important
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

@media only screen and (min-width: $small-width) {
  .start-sm {
    justify-content: flex-start;
    text-align: start
  }
  .center-sm {
    justify-content: center;
    text-align: center
  }
  .end-sm {
    justify-content: flex-end;
    text-align: end
  }
  .top-sm {
    align-items: flex-start
  }
  .middle-sm {
    align-items: center
  }
  .bottom-sm {
    align-items: flex-end
  }
  .around-sm {
    justify-content: space-around
  }
  .between-sm {
    justify-content: space-between
  }
  .flex-column-sm {
    flex-direction: column;
  }
  .flex-row-sm {
    flex-direction: row;
  }
  .text-left-sm {
    text-align: left !important
  }
  .text-right-sm {
    text-align: right !important;
  }
  .text-center-sm {
    text-align: center !important
  }
  .rounded-sm {
    border-radius: .25rem !important;
  }
  .rounded-0-sm {
    border-radius: 0 !important;
  }
}

@media only screen and (min-width: $tablet-width) {
  .start-md {
    justify-content: flex-start;
    text-align: start
  }
  .center-md {
    justify-content: center;
    text-align: center
  }
  .end-md {
    justify-content: flex-end;
    text-align: end
  }
  .top-md {
    align-items: flex-start
  }
  .middle-md {
    align-items: center
  }
  .bottom-md {
    align-items: flex-end
  }
  .around-md {
    justify-content: space-around
  }
  .between-md {
    justify-content: space-between
  }
  .flex-column-md {
    flex-direction: column;
  }
  .flex-row-md {
    flex-direction: row;
  }
  .text-left-md {
    text-align: left !important
  }
  .text-right-md {
    text-align: right !important;
  }
  .text-center-md {
    text-align: center !important
  }
  .rounded-md {
    border-radius: .25rem !important;
  }
  .rounded-0-md {
    border-radius: 0 !important;
  }
}

@media only screen and (min-width: $large-width) {
  .center-lg {
    justify-content: center;
    text-align: center
  }
  .end-lg {
    justify-content: flex-end;
    text-align: end
  }
  .top-lg {
    align-items: flex-start
  }
  .middle-lg {
    align-items: center
  }
  .bottom-lg {
    align-items: flex-end
  }
  .around-lg {
    justify-content: space-around
  }
  .between-lg {
    justify-content: space-between
  }
  .flex-column-lg {
    flex-direction: column;
  }
  .flex-row-lg {
    flex-direction: row;
  }
  .text-left-lg {
    text-align: left !important
  }
  .text-right-lg {
    text-align: right !important;
  }
  .text-center-lg {
    text-align: center !important
  }
  .rounded-lg {
    border-radius: .25rem !important;
  }
  .rounded-0-lg {
    border-radius: 0 !important;
  }
}

@media only screen and (min-width: $extra-large-width) {
  .start-xl {
    justify-content: flex-start;
    text-align: start
  }
  .center-xl {
    justify-content: center;
    text-align: center
  }
  .end-xl {
    justify-content: flex-end;
    text-align: end
  }
  .top-xl {
    align-items: flex-start
  }
  .middle-xl {
    align-items: center
  }
  .bottom-xl {
    align-items: flex-end
  }
  .around-xl {
    justify-content: space-around
  }
  .between-xl {
    justify-content: space-between
  }
  .flex-column-xl {
    flex-direction: column;
  }
  .flex-row-xl {
    flex-direction: row;
  }
  .text-left-xl {
    text-align: left !important
  }
  .text-right-xl {
    text-align: right !important;
  }
  .text-center-xl {
    text-align: center !important
  }
  .rounded-xl {
    border-radius: .25rem !important;
  }
  .rounded-0-xl {
    border-radius: 0 !important;
  }
}


/* positions */

.p-relative {
  position: relative !important
}

.p-fixed {
  position: fixed !important
}


/* end positions */

.d-none {
  display: none !important
}

.d-block {
  display: block !important
}

.d-flex {
  display: flex !important
}

@media (min-width: $small-width) {
  .d-sm-none {
    display: none !important
  }
  .d-sm-block {
    display: block !important
  }
  .d-sm-flex {
    display: flex !important
  }
}

@media (min-width: $tablet-width) {
  .d-md-none {
    display: none !important
  }
  .d-md-inline {
    display: inline !important
  }
  .d-md-block {
    display: block !important
  }
  .d-md-flex {
    display: flex !important
  }
}

@media (min-width: $large-width) {
  .d-lg-none {
    display: none !important
  }
  .d-lg-block {
    display: block !important
  }
  .d-lg-flex {
    display: flex !important
  }
}

@media (min-width: $extra-large-width) {
  .d-xl-none {
    display: none !important
  }
  .d-xl-block {
    display: block !important
  }
  .d-xl-flex {
    display: flex !important
  }
}
