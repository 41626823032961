.top-navbar {
  .lang-box {
    position: relative;
  }
  .lang-icon {
    transform: scale(0.7);
    margin-left: 1em;
    cursor: pointer;
    position: relative;
    margin-right: -2px;
    z-index: 20;
    margin-top: 3px;
    @media (max-width: $tablet-width) {
      margin-left: 1.5em;
    }
  }
  .language-block {
    position: absolute;
    z-index: 12;
    width: 356px;
    height: 314px;
    right: -8.5px;
    top: 26px;
    padding: 10px 20px 15px;
    .font-13 {
      padding-bottom: 7px;
      margin-bottom: 15px;
      border-bottom: 1px solid #4d4d4d;
    }
    .rows {
      margin: 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .mr-3 {
        margin-right: 15px;
      }
    }
    @media (max-width: $tablet-width) {
      width: 300px;
    }
    @media (max-width: $mobile-width) {
      width: 270px;
    }
  }
}
.top-items {
  display: flex;
  li {
    padding-left: 25px;
    position: relative;
    a {
      font-size: 14px;
      font-weight: 600;
      color: $blackGrey;
      line-height: 1.71;
      position: relative;
      &:hover {
        color: white;
        text-decoration: none;
        transition: ease all 0.2;
      }
      @media (max-width: $tablet-width) {
        font-size: 12px;
      }
    }
    &:last-child {
      padding-right: 0;
      margin: 0;
    }
    &:not(:last-child):after {
      content: '|';
      position: absolute;
      right: -16px;
      top: 0;
      font-weight: 600;
      @media (max-width: $small-width) {
        display: none;
      }
    }
  }
}
.flag-small{
  width: 32px;
}
