@for $i from 1 through 50 {
  .mt-#{$i} {
    margin-top: #{$i}px;
  }
}
@for $i from 1 through 50 {
  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }
}
@for $i from 1 through 50 {
  .mr-#{$i} {
    margin-right: #{$i}px;
  }
}

@for $i from 1 through 50 {
  .ml-#{$i} {
    margin-left: #{$i}px;
  }
}

@for $i from 1 through 50 {
  .pt-#{$i} {
    padding-top: #{$i}px;
  }
}
@for $i from 1 through 50 {
  .pb-#{$i} {
    padding-bottom: #{$i}px;
  }
}
@for $i from 1 through 50 {
  .pr-#{$i} {
    padding-right: #{$i}px;
  }
}

@for $i from 1 through 50 {
  .pl-#{$i} {
    padding-left: #{$i}px;
  }
}

// font size
@for $i from 1 through 50 {
  .font-#{$i} {
    font-size: #{$i}px;
  }
}

$fontsize: (1,2,8);

@each $size in $fontsize {
  .font-#{$size} {
    font-size: #{$size}px;
  }
}
