@import "../abstracts/variables";

.vg-nav {
    position: relative;
    z-index: 100;
    width: 100%
}

.vg-nav>ul:not(.vg-nav-main-container) {
    display: none
}

.vg-nav-main-container {
    padding: 0;
    margin: 0;
    display: block
}

.vg-nav-main-container li {
    list-style: none
}

.vg-nav-main-container>li {
    display: inline-block
}

.vg-nav-main-container span.toggle {
    //transition: all 0.4s ease;
    transform: rotate(0)
}

.vg-nav-main-container span.toggle span.default {
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    width: 0;
    height: 0;
    transform: rotate(0);
    display: inline-block;
    margin-left: .355em;
    vertical-align: .255em
}

.vg-nav-main-container .dropdown {
    position: relative
}

.vg-nav-main-container .dropdown ul {
    padding: 0
}

.vg-nav-main-container .dropdown>ul {
    transition: all 0.4s ease;
    position: absolute;
    top: 120%;
    opacity: 0;
    visibility: hidden;
    width: 200px
}

.vg-nav-main-container .dropdown>ul .left {
    left: 0
}

.vg-nav-main-container .dropdown>ul .right {
    right: 0
}

.vg-nav-main-container .dropdown>ul ul {
    transition: all 0.4s ease;
    position: absolute;
    top: 20%;
    opacity: 0;
    visibility: hidden;
    width: 200px
}

.vg-nav-main-container .dropdown>ul .dropdown ul.left {
    left: 100%
}

.vg-nav-main-container .dropdown>ul .dropdown ul.right {
    right: 100%
}

.vg-nav-main-container .dropdown>ul .dropdown.show>ul {
    visibility: visible;
    opacity: 1;
    top: 0
}

.vg-nav-main-container .dropdown.show>ul {
    visibility: visible;
    opacity: 1;
    top: 100%
}

.vg-nav-main-container .dropdown.show>a span.toggle .default {
    transform: rotate(-180deg)
}

.vg-nav-main-container .dropdown-mega.show>a span.toggle .default {
    transform: rotate(-180deg)
}

.vg-nav-main-container .dropdown-mega .dropdown-mega-container {
    transition: all 0.4s ease;
    position: absolute;
    top: 120%;
    opacity: 0;
    visibility: hidden;
    left: 0;
    width: 100%;
}

.vg-nav-main-container .dropdown-mega.show>a span.toggle {
    transform: rotate(-180deg)
}

.vg-nav-main-container .dropdown-mega.show>.dropdown-mega-container {
    visibility: visible;
    opacity: 1;
    top: 100%
}

.vg-nav-main-container.vg-nav-cloned>li {
    display: block
}

.vg-nav-main-container.vg-nav-cloned .dropdown ul {
    position: relative;
    width: 100%;
    height: 0
}

.vg-nav-main-container.vg-nav-cloned .dropdown ul .left,
.vg-nav-main-container.vg-nav-cloned .dropdown ul .right {
    left: 0 !important
}

.vg-nav-main-container.vg-nav-cloned .dropdown.show>ul {
    height: initial
}

.vg-nav-main-container.vg-nav-cloned .dropdown-mega .dropdown-mega-container {
    position: relative;
    height: 0;
    min-height: 0;
    top: 0;
    display: none
}

.vg-nav-main-container.vg-nav-cloned .dropdown-mega.show .dropdown-mega-container {
    display: block;
    height: auto;
    top: 0
}

.vg-nav-hover>ul .dropdown:hover>a span.toggle {
    transform: rotate(-180deg)
}

.vg-nav-hover>ul .dropdown:hover>ul {
    visibility: visible;
    opacity: 1;
    top: 100%
}

.vg-nav-hover>ul .dropdown:hover .dropdown:hover>ul {
    visibility: visible;
    opacity: 1;
    top: 0
}

.vg-nav-hover>ul .dropdown-mega:hover .dropdown-mega-container {
    visibility: visible;
    opacity: 1;
    top: 100%
}

.vg-nav-hover>ul .dropdown-mega:hover>a span.toggle {
    transform: rotate(-180deg)
}

.vg-nav-hamburger {
    display: none;
    //width: 35px;
    //height: 35px;
    position: relative
}

.vg-nav-hamburger span {
    cursor: pointer;
    border-radius: 2px;
    height: 3px;
    width: 35px;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    display: block;
    transition: all 0.4s ease;
    transform: rotate(0);
    opacity: 1
}

.vg-nav-hamburger span:first-child {
    top: 3px
}

.vg-nav-hamburger span:nth-child(2) {
    top: 13px
}

.vg-nav-hamburger span:last-child {
    top: 23px
}

.vg-nav-hamburger.show span:first-child {
    top: 13px;
    opacity: 0
}

.vg-nav-hamburger.show span:nth-child(2) {
    transform: rotate(45deg)
}

.vg-nav-hamburger.show span:last-child {
    transform: rotate(-45deg);
    top: 13px
}

.vg-nav-collapse {
    display: block;
    position: relative;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease
}

.vg-nav-collapse.show {
    height: 100%;
    opacity: 1;
    visibility: visible
}

.vg-nav-sidebar {
    display: block;
    position: fixed;
    top: 0;
    opacity: 0;
    height: 100%;
    z-index: 9990;
    transition: all 0.4s ease;
    background: #fff
}

@media only screen and (min-width: 1200px) {
    .vg-nav-sidebar.left {
        left: -25%;
        width: 25%
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .vg-nav-sidebar.left {
        left: -33%;
        width: 33%
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .vg-nav-sidebar.left {
        left: -50%;
        width: 50%
    }
}

@media only screen and (max-width: 767.98px) {
    .vg-nav-sidebar.left {
        left: -75%;
        width: 75%
    }
}

@media only screen and (max-width: 479.98px) {
    .vg-nav-sidebar.left {
        left: -100%;
        width: 100%
    }
}

@media only screen and (min-width: 1200px) {
    .vg-nav-sidebar.right {
        right: -25%;
        width: 25%
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .vg-nav-sidebar.right {
        right: -33%;
        width: 33%
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .vg-nav-sidebar.right {
        right: -50%;
        width: 50%
    }
}

@media only screen and (max-width: 767.98px) {
    .vg-nav-sidebar.right {
        right: -75%;
        width: 75%
    }
}

@media only screen and (max-width: 479.98px) {
    .vg-nav-sidebar.right {
        right: -100%;
        width: 100%
    }
}

.vg-nav-sidebar.show.left {
    left: 0 !important;
    opacity: 1
}

.vg-nav-sidebar.show.right {
    right: 0 !important;
    opacity: 1
}

.vg-nav-sidebar__close {
    position: absolute;
    font-size: 56px;
    font-weight: 300;
    top: 0;
    right: 15px;
    color: #333333;
    cursor: pointer
}

.vg-nav-sidebar__content {
    margin-top: 50px;
    height: calc(100% - 60px);
    overflow-y: auto;
    overflow-x: hidden
}

.vg-nav-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 0;
    transition: all 0.1s ease;
    top: 0;
    background: rgba(51, 51, 51, 0.7);
    transition-delay: .1s;
    z-index: 9989
}

.vg-nav-overlay.right {
    left: -100%
}

.vg-nav-overlay.right.show {
    left: 0;
    opacity: 1
}

.vg-nav-overlay.left {
    right: -100%
}

.vg-nav-overlay.left.show {
    right: 0;
    opacity: 1
}

@media only screen and (min-width: 1200px),
only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px),
only screen and (max-width: 479.98px) {
    .vg-nav-xl .vg-nav-hamburger {
        display: block
    }
    .vg-nav-xl>ul {
        display: none
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px),
only screen and (max-width: 479.98px) {
    .vg-nav-lg .vg-nav-hamburger {
        display: block
    }
    .vg-nav-lg>ul {
        display: none
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px),
only screen and (max-width: 479.98px) {
    .vg-nav-md .vg-nav-hamburger {
        display: block
    }
    .vg-nav-md>ul {
        display: none
    }
}

@media only screen and (max-width: 767.98px),
only screen and (max-width: 479.98px) {
    .vg-nav-sm .vg-nav-hamburger {
        display: block
    }
    .vg-nav-sm>ul {
        display: none
    }
}

@media only screen and (max-width: 479.98px) {
    .vg-nav-xs .vg-nav-hamburger {
        display: block
    }
    .vg-nav-xs>ul {
        display: none
    }
}

a {
    transition: all .3s ease;
}



.vg-nav>ul>li>a {
    font-size: 16px;
    line-height: 1.2;
    display: block;
    color: #232323;
    text-decoration: none
}

//.vg-nav>ul>li.active a,
//.vg-nav>ul>li a:hover {
//    color: #236da9;
//    span{
//        .default{
//            color: red;
//        }
//    }
//}

.vg-nav .dropdown ul {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.15)
}

.vg-nav .dropdown ul li a {
    text-decoration: none;
    font-size: 14px;
    display: block;
    padding: 15px;
    color: #232323;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: none
}

.vg-nav .dropdown ul li:first-child a {
    border: 1px solid rgba(0, 0, 0, 0.1)
}

.vg-nav .dropdown ul ul {
    background: rgba(255, 255, 255, 0.9)
}

.vg-nav .dropdown-mega .dropdown-mega-container {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
    padding: 15px
}

.vg-nav-hamburger {
    margin: 0
}

.vg-nav-sidebar .vg-nav-sidebar__content {
    width: 100%;
    padding: 0 20px 20px 20px
}

.vg-nav-collapse ul {
    width: 100%;
    padding: 20px 0
}

.vg-nav-sidebar__content>ul>li>a,
.vg-nav-collapse>ul>li>a {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #f2f2f2;
    text-decoration: none;
    color: #333333
}

.vg-nav-sidebar__content>ul ul,
.vg-nav-collapse>ul ul {
    background: rgba(0, 0, 0, 0.02)
}

.vg-nav-sidebar__content>ul ul li>a,
.vg-nav-collapse>ul ul li>a {
    display: block;
    padding: 10px;
    border-bottom: 1px solid #f5f5f5;
    text-decoration: none;
    color: #333333;
    font-size: .95rem
}

.vg-nav-sidebar__content .dropdown-mega-container,
.vg-nav-collapse .dropdown-mega-container {
    background: rgba(0, 0, 0, 0.02);
    border-bottom: 1px solid #f5f5f5;
    padding: 15px
}
