@import '../abstracts/variables';
.footer {
    display: grid;
    grid-template-columns: 210px 4fr;
    grid-gap: 30px;
    padding-bottom: 29px;
    padding-top: 23px;
    @media (max-width: $small-width) {
        grid-template-columns: 1fr;
    }
    .left-block {
        @media (min-width: $small-width) {
            max-width: 210px;
        }
        .pay-logo {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 9px;
            margin-top: 9px;
            img {
                width: 46px;
                height: 32px;
            }
        }
        .icons {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 3fr;
            grid-gap: 8px;
            margin-top: 18px;
            i {
                color: #ac8e56;
                border: solid 1px #ac8e56;
                width: 31px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                cursor: pointer;
                &:hover {
                    background-color: #ac8e56;
                    transition: all ease .3s;
                    color: $white;
                }
                @media (max-width: 992px) {
                    padding: 3px 5px;
                }
            }
        }
    }
    .right-block {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 15px;
        @media (max-width: $large-width) {
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: $tablet-width) {
            grid-template-columns: 1fr;
        }
    }
    .nav-footer {
        margin-top: 4px;
        .link-footer {
            display: block;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: left;
            color: #eaeaea;
            padding-left: 15px;
            white-space: nowrap;
            &:hover {
                color: #ac8e56;
                transition: all ease .3s;
            }
        }
    }
    .number {
        font-size: 15px!important;
        font-weight: bold!important;
        color: #ac8e56!important;
        text-transform: uppercase;
    }
    .flags {
        margin-top: 8px;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        @media(max-width: $mobile-width) {
            grid-column-gap: 62px;
            grid-row-gap: 7px;
        }
        img {
            @media (min-width: $large-width) {
                width: 100%;
            }
        }
    }
}

.subfooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    .grid {
        display: grid;
        grid-template-columns: 2fr 6fr;
        @media (max-width: $large-width) {
            grid-template-columns: 3fr;
        }
        .nav-footer {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            @media (max-width: $small-width) {
                justify-content: center;
            }
        }
        .copy {
            color: #818181;
            font-size: 12px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 650px) {
                text-align: center;
                margin: .5em 0;
            }
        }
        a {
            color: #818181;
            font-size: 12px;
            &:hover {
                color: #ac8e56;
                opacity: .7;
                text-decoration: none;
            }
        }
        .nav-link {
            padding-right: 1em;
            padding-left: 1em;
            padding-top: .25rem !important;
            padding-bottom: .25rem !important;
        }
        li {
            position: relative;
            &:last-child {
                padding-right: 0;
                margin: 0;
            }
            &:not(:last-child):after {
                content: '|';
                position: absolute;
                right: -5px;
                top: 0;
                font-weight: 600;
                color: #818181;
            }
        }
        @media (max-width: 650px) {
            padding-bottom: 1em;
        }
    }
}

.btn-header {
    height: 30px;
    border: solid 1px #4d4d4d;
    background-color: #393939;
    font-size: 13px;
    font-weight: bold;
    color: #ac8e56;
    display: flex;
    align-items: center;
    justify-content: start;
    border-radius: 3px;
    padding-left: 15px;
    text-transform: uppercase;
    @media (max-width: 992px) {
        font-size: 11px;
        text-transform: capitalize;
    }
}
