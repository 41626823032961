.content {
  padding-top: 20px;
  padding-bottom: 33px;
  display: grid;
  grid-template-columns: 210px auto;
  grid-column-gap: 30px;
  @media (max-width: $large-width) {
    grid-column-gap: 15px;
  }
  @media (max-width: $tablet-width) {
    grid-template-columns: 1fr;
  }

  .content-left {
    display: grid;
    grid-row-gap: 28px;
    height: 50%;
    grid-template-rows: minmax(auto, 200px) minmax(auto, 400px) minmax(auto, 500px) minmax(auto, 450px);
    @media (max-width: $tablet-width) {
      grid-template-rows: minmax(auto,200px) minmax(auto,232px) minmax(auto,500px) auto;    grid-row-gap: 28px;
      grid-row-gap: 15px;
    }
  }

  .content-right {
    display: grid;
    grid-row-gap: 15px;
    grid-template-rows: 15px auto minmax(433px, auto);
  }

  .content-right-middle {
    display: grid;
    grid-column-gap: 15px;
    grid-template-columns: minmax(auto, 566px) minmax(auto, 178px);
    @media (max-width: $small-width) {
      grid-template-columns: 1fr;
    }

    .right-left {
      display: grid;
      grid-row-gap: 15px;
      grid-template-rows: minmax(300px, auto) minmax(300px, auto) minmax(800px, auto);
      @media (min-width: $extra-large-width) {
        grid-template-rows: 300px 300px 776px!important;
      }
      @media (max-width: $extra-large-width) {
        grid-template-rows: minmax(300px, auto) minmax(300px, auto) minmax(auto, auto);
      }
    }

    .right-right {
      display: grid;
      grid-row-gap: 16.7px;
      grid-template-rows: min(300px) min(178px) min(350px) min(326px) min(185px);
      @media (max-width: $small-width) {
        margin-top: 15px;
        grid-template-rows: min(300px) min(178px) min(350px) min(250px) min(185px);
      }
    }
  }

  .middle-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: $small-width) {
      grid-template-columns: 1fr;
      padding-bottom: 13px;
    }

    .piano-image {
      justify-self: end;
      @media (max-width: $small-width) {
        justify-self: center;
      }
    }

    .pr-11 {
      padding-right: 11px !important;
    }
  }

  .text-block {
    padding: 24px 34px 18px 28px;
    @media (max-width: $large-width) {
    padding: 16px 20px 18px 13px;
    }
  }

  p {
    padding-bottom: 0;
  }

}

.bottom-form {
  .main-form {
    padding: 15px 29px 17px 28px;
  }

  .head-form {
    background-color: #d6d6d6;
    height: 47px;
    color: #313131;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    padding: 0 29px;
  }
  p{
    height: 31px;
    color: #313131;
    font-size: 13px;
    line-height: 18px;
    margin-top: 0;
  }
  .grid-form{
    margin-top: 33px;
    display: grid;
    grid-column-gap: 47px;
    grid-template-columns: 1fr 1fr;
    @media (max-width: $large-width) {
      grid-template-columns: 1fr;
    }
    .input-grey{
      width: 200px;
      height: 35px;
      border: 1px solid #d6d6d6;
      background-color: #f1f1f1;
      border-radius: 3px;
      padding: 10px;
      font-size: 13px;

      &:hover, &:focus{
        border: 1px solid $brownLight!important;
        outline: none;
      }
      @media (max-width: $small-width) {
        width: 150px;
        margin-top: 15px;
      }
    }
    span {
      color: #6b6b6b!important;
    }
    .d-flex{
      &:not(:first-child) {
        margin-top: 13px;
      }
      @media (max-width: $large-width) {
        margin-top: 13px;
      }
    }

  }
  .bottom-button{
    margin-top: 27px;
    button{
      width: 200px;
      height: 35px;
      background-color: #ac8e56;
      color: #ffffff;
      font-size: 13px;
      font-weight: 400;
      border: none;
      border-radius: 3px;
      @media (max-width: $small-width) {
        margin-top: 15px;
      }
    }
  }
}

.transport-contact {
  border: 3px solid #ac8e56;

  .head {
    height: 60px;
  }

  ul {
    padding: 16px 19px 36px 14px;

    li {
      color: #272727;
      font-weight: 400;
      letter-spacing: -0.33px;

      &:not(:first-child) {
        margin-top: 12px;
      }
    }
  }

  .transport-contact-list {
    img {
      width: 39px;
      height: 39px;
    }
  }
}

.transport-karrier {
  .header {
    height: 60px;
    background-color: #ac8e56 !important;
    letter-spacing: -0.6px;
  }

  .content-transport {
    padding: 19px 15px 24px;

    button {
      margin-top: 41px;
      width: 100px;
      height: 25px;
      border: 2px solid #a6a6a6;
      background-color: #4d4d4d;
      color: #ffffff;
      border-radius: 10px;
      font-size: 13px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  p {
    color: #6b6b6b;
  }
}
