.product-navbar {
  padding-top: 17px;
  min-height: 100px;
  @media (max-width: $large-width) {
    padding-bottom: 15px;
  }

  .image-logo {
    @media (max-width: $large-width) {
      display: flex;
      align-items: center;
    }
    @media (max-width: $small-width) {
      padding-bottom: 15px;
      justify-content: center;
    }
  }

  .grid-nav {
    display: grid;
    grid-column-gap: 60px;
    grid-template-columns: 178px auto;
    @media (max-width: $small-width) {
      grid-template-columns: 1fr;
    }

    .grid-filter {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 15px;
      @media (max-width: $large-width) {
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 15px;
      }
      @media (max-width: $tablet-width) {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 15px;
      }
      @media (max-width: $mobile-width) {
        grid-template-columns: 2fr;
        grid-row-gap: 15px;
      }

      div {
        border: #a0a0a0 1px solid;
      }

      button {
        height: 28px;
        text-align: left;
        padding: 0 10px;
      }

      .btn-down {
        @media (max-width: $large-width) {
          //grid-row: 2/3;
        }
      }

      .btn-up {
        @media (max-width: $large-width) {
          grid-row: 3/3;
        }
        @media (max-width: $tablet-width) {
          grid-row: 4/4;
        }
        @media (max-width: $mobile-width) {
          grid-row: 7;
        }
      }
    }
  }
}
