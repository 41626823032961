// text color
.bg-orange {
    background: $orange;
}

.black-2 {
    color: $black-2;
}

.brownLight-color {
    color: $brownLight!important;
}

.grey-text-faq {
    color: #3b3b3b;
}

.text-orange {
    color: $orange;
}

.black-color {
    color: $blackGrey!important;
}

.blackGrey-color {
    color: $blackGrey;
}

.white {
    color: $white !important;
}

.griLight-1 {
    color: $griLight-1!important;
}

.black-title {
    color: $black-title
}

.gri-color {
    color: $gri;
}

// background color
.grey-lightbg-1 {
    background: $griLight-1!important;
}

.body-background {
    background: $body !important;
}

.grey-head-background {
    background: $grey-head;
}

.body-grey {
    background-color: $griLight;
}

.gri {
    background-color: $gri !important;
}

.bg-brown {
    background: $brownLight;
}

.bg-footer {
    background: $footer;
}

.bg-black {
    background: $blackGrey!important;
}

