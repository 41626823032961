.home-dropdown {
    li {
      cursor: pointer;
      &:hover {
        opacity: 0.9;
        color: #272727!important;
      }
  }
}
.black-dropdown{
  color: #313131!important;
}

.dropdown-menu {
  display: none;
  width: 100%;
  text-align: left;
  background: $brownLight;
  position: absolute;
  user-select: none;
  top: 30px;
  border-top: 1px solid $black-title;

  li {
    padding: 5px 10px;
    font-weight: 600;
    color: $black-title;
    cursor: pointer;

    &:hover {
      background: $white;
      transition: all ease 0.3s;
    }
  }
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  display: none;
}

.toggle,
.dropdown-menu {
  width: 100%;
}

.is-active .dropdown-menu {
  display: block;
}
