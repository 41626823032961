.s-bold {
    span {
        font-weight: bold;
        cursor: pointer;
        font-size: 25px;
        &:hover {
            text-decoration: underline;
        }
    }
}

.a-z {
    margin-top: 20px;
    margin-bottom: 97px;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 210px auto;
    @media (max-width: $small-width) {
        grid-template-columns: 1fr;
    }
    .left {
        display: grid;

        .letters {
            .grid-letters {
                display: grid;
                grid-gap: 10px;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: repeat(7, 45px);
                div {
                    background-color: #d2cfcd;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                    font-size: 30px;
                    color: $gri;
                    cursor: pointer;
                    &:hover {
                        color: $white;
                        background: $brownLight;
                        transition: all ease 0.3s;
                    }
                }
            }
        }
    }
    .right {
        display: grid;
        grid-row-gap: 15px;
        grid-template-rows: 16px;
        .g-inside {
            display: grid;
            grid-row-gap: 35px;
            grid-template-rows: minmax(1000px, auto) minmax(400px, auto);
            h1 {
                margin: 0;
            }
            .letters-blocks-down {
                display: grid;
                grid-template-columns: 55px auto;
                .head-letters {
                    min-height: 60px;
                    display: flex;
                    align-items: center;
                    background: $griLight;
                    justify-content: space-around;
                    margin-bottom: 17px;
                    flex-wrap: wrap;
                    img {
                        @media (max-width: $tablet-width) {
                            padding: 20px;
                        }
                    }
                }
            }
            .letters-blocks-4 {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                @media (max-width: $tablet-width) {
                    grid-template-columns: auto auto;
                    grid-gap: 10px;
                }
            }
            .brown {
                span {
                    color: $brownLight;
                    font-weight: bold;
                    font-size: 25px;
                    cursor: pointer;
                    @media (max-width: $large-width) {
                        padding-right: 4px;
                        padding-left: 4px;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .padding-mobile {
        @media (max-width: $mobile-width) {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
    a {
        color: #313131;
        &:hover {
            color: $brownLight;
            transition: all ease 0.3s;
        }
    }
}
.categorii {
    .mt-12 {
        height: 191px;
        overflow-y: scroll;
    }
    input {
        color: #a0a0a0;
        height: 24px;
        border-radius: 3px;
        border: solid 1px #a0a0a0;
        background-color: #ffffff;
        line-height: 1.82;
        padding-left: 10px;
        &:focus {
            outline: $brownLight!important;
        }
    }
}
