.header-tabs {
  display: grid;
  grid-template-columns: 100px 180px 120px auto;
  @media (max-width: $tablet-width) {
    grid-template-columns: auto auto auto;
  }

  .border-right0 {
    @media (max-width: $tablet-width) {
      border-right: none;
    }
  }
}

.pl-19 {
  padding-left: 19px;
}

.pr-23 {
  padding-right: 23px;
}

.pb-37 {
  padding-bottom: 37px;
}

.search {
  .d-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 36px;
    grid-row-gap: 13px;
    @media (max-width: $large-width) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: $tablet-width) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: $mobile-width) {
      grid-template-columns: 1fr;
    }

    .mb-1 {
      margin-bottom: 7px;
    }

    .button-right {
      grid-column: 4/5;
      margin-top: 26px;

      @media (max-width: $large-width) {
        grid-column: 3/4;
      }
      @media (max-width: $tablet-width) {
        grid-column: 2/3;
      }
      @media (max-width: $small-width) {
        grid-column: 1/3;
      }
      @media (max-width: $mobile-width) {
        grid-column: 1/2;
      }

      button {
        height: 28px;
        border-radius: 3.7px;
        background-color: #ac8e56;

        &:hover {
          background-color: #4d4d4d;
          transition: all ease 0.3s;
        }
      }
    }
  }

  .mt-35 {
    margin-top: 35px;
    padding-bottom: 92px;
    display: grid;
    grid-column-gap: 31px;
    grid-template-columns: 210px auto;
    @media (max-width: $small-width) {
      grid-template-columns: repeat(1, 1fr);
    }

    .left {
      display: grid;
      grid-row-gap: 27px;
      grid-template-rows: 178px 288px;
      @media (max-width: $small-width) {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        height: 100%;
        grid-template-rows: 288px;
      }
      @media (max-width: $mobile-width) {
        grid-template-columns: 1fr;
      }

      .bg-orange {
        h5 {
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      .article {
        h5 {
          padding: 19px 28px 18px;
        }

        img {
          @media (max-width: $small-width) {
            //width: 100%;
          }
        }

      }
    }

    .right {
      display: grid;
      grid-column-gap: 13px;
      grid-row-gap: 36px;
      grid-template-columns: repeat(4, 1fr);
      @media (max-width: $large-width) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: $tablet-width) {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 10px;
      }
      @media (max-width: $small-width) {
        margin-top: 10px;
      }
      @media (max-width: $mobile-width) {
        grid-template-columns: 1fr;
      }
    }
  }

  .input-search {
    height: 28px;
    border-radius: 3.1px;
    border: solid 1px #a0a0a0;
    background-color: #f1f1f1;
    font-size: 13px;
    padding-left: 10px;
    color: #272727;

    &:focus {
      outline: none;
    }
  }

  .hover-1 {
    &:hover {
      button {
        background-color: #4d4d4d;
        border: solid 2px rgba(181, 175, 175, .5);
        transition: all ease 0.3s;
      }
    }
  }
}

.border-tab {
  border-bottom: solid 1px #ffffff;
}

// select
.select-1 {
  width: 100%;
  height: 28px;
  font-weight: 500;
  padding-left: 10px;

  &:focus {
    outline: none;
  }
}
