.boxes {
    margin-bottom: 112px;
    margin-top: 40px;
    .bg-white {
        padding: 0 10px 20px 10px;
    }
    .price-text {
        padding: 0 110px;
        margin-top: 20px;
    }
    .line-through {
        border-bottom: 1px solid #a0a0a0;
        position: relative;
        bottom: 28px!important;
    }
    .blocks {
        display: grid;
        grid-template-columns: minmax(auto, 418px) repeat(3, minmax(130px, auto));
        grid-gap: 16px;
        @media (max-width: $large-width) {
            grid-template-columns: minmax(auto, 350px) repeat(3, minmax(120px, auto));
        }
        @media (max-width: $tablet-width) {
            grid-template-columns: 1fr;
        }
        h2 {
            padding-top: 23px;
            line-height: 1;
            @media (max-width: $small-width) {
                font-size: 18px;
            }
        }
        .mt-70 {
            padding-top: 70px;
            @media (max-width: $tablet-width) {
                padding-top: 30px!important;
            }
        }
        .price-box {
            height: 60px;
            margin-top: 17px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }
    }
    .types {
        margin-top: 23px;
        li {
            border-bottom: solid 1px #dcdcdc;
            height: 48px;
            display: flex;
            align-items: center;
            span {
                font-size: 14px;
                padding-right: 15px;
                @media (max-width: $mobile-width) {
                    font-size: 13px;
                }
            }
            div {
                @media (max-width: $mobile-width) {
                    font-size: 17px!important;
                }
            }
            i {
                line-height: 0.6;
            }
        }
    }
    h3 {
        margin-top: 40px;
        word-break: break-all;
    }
    .pb-85 {
        @media (min-width: $tablet-width) {
            padding-bottom: 85px;
        }
    }
    .mt-115 {
        margin-top: 115px!important;
        @media (max-width: $tablet-width) {
            margin-top: 30px!important;
        }
    }
    .mt-86 {
        margin-top: 86px!important;
        @media (max-width: $tablet-width) {
            margin-top: 30px!important;
        }
    }
    h3,
    h2 {
        @media (max-width: $small-width) {
            font-size: 16px!important;
        }
        span {
            @media (max-width: $small-width) {
                font-size: 15px!important;
            }
        }
    }
    .hover-box {
        &:hover {
            .h-10 {
                background: $orange!important;
                transition: all ease 0.3s;
            }
            .price-box {
                background: $blackGrey;
                transition: all ease 0.3s;
            }
            button {
                color: $white;
                background: $orange;
                transition: all ease 0.3s;
            }
        }
    }
    .price-text {
        @media (max-width: $tablet-width) {
            padding: 0;
        }
    }
    .types {
        .between-xs {
            @media (max-width: $mobile-width) {
                text-align: left!important;
            }
        }
    }
}