.swiper-container,
.swiper-container-1 {
    width: 100%;
    height: 200px;
}

.swiper-slide {
    background-size: cover;
    background-position: 50%;
    min-height: 25vh;
    display: -webkit-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.swiper-pagination-bullet {
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    opacity: 1;
}

.swiper-pagination-bullet-active {
    background-color: #fff;
}

.swiper-button-container {
    background-color: rgba(0, 0, 0, 0.25);
}

.swiper-button-prev {
    background-image: url("../img/prev-icon.png")!important;
    left: 33px!important;
}

.swiper-button-next {
    background-image: url("../img/next-icon.png")!important;
}

.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: 50%;
    width: 15px!important;
    height: 29px!important;
    margin-top: -13px!important;
    z-index: 10;
    cursor: pointer;
    background-size: 140% 105% !important;
    background-position: center;
    background-repeat: no-repeat;
}

.swiper-slide:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.swiper-slide h2 {
    pointer-events: none;
    opacity: 0;
    color: #ffffff;
    font-size: calc(5vw);
    letter-spacing: -1px;
    text-transform: uppercase;
    text-shadow: 0 5px 5px rgba(0, 0, 0, 0.01);
}

.swiper-slide-active h2 {
    opacity: 1;
    transform: translateY(0%) scale(1);
    transition: 1s ease;
}

.swiper-button-prev {
    margin-left: -22px;
}
