.content-accordion {
  margin-bottom: 6px;
  .list-accordion {
    .d-flex {
      padding: 14px 0;
      //padding: 16.3px 0;
    }
  }

  .hidden {
    display: none;
  }

}

.b-bottom {
  border-bottom: 1px solid #a0a0a0;
}

.b-top {
  border-top: 1px solid #a0a0a0;
}
