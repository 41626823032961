@import "../abstracts/variables";

.search-list {
  padding-top: 20px;
  padding-bottom: 41px;

  .grids-1 {
    grid-gap: 30px;
    grid-template-columns: 210px 1fr;
    @media (max-width: $small-width) {
      grid-template-columns: 1fr;
    }
  }

  .filters {
    display: grid;
    grid-template-columns: 1fr auto;
    padding-bottom: 4px;
    margin-top: 12px;
    @media (max-width: $small-width) {
      grid-template-columns: 1fr;
      border-bottom: none;
    }

    .text-header {
      @media (max-width: $small-width) {
        padding-bottom: 10px;
      }
    }

    .filter-buttons {
      .display {
        width: 120px;
        height: 24px;
        border-radius: 2px;
        border: solid 1px #a0a0a0;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-right: 19px;

        i {
          height: 100%;
          color: #a0a0a0;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .active {
          background: #a0a0a0;

          i {
            color: $white !important;
          }
        }
      }

      .border-right {
        border-right: 1px solid #a0a0a0 !important;
      }
    }

    .sort {
      width: 130px;
      height: 24px;
      border: solid 1px #a0a0a0;
      border-radius: 2px;
      padding: 0 9px;
      display: grid;
      grid-template-columns: 10fr 1fr;
      align-content: center;
      line-height: 1.6;

      i {
        font-size: 12px;
        margin-top: 2px;
        padding-left: 7px;
      }
    }
  }

  .search-result {
    grid-gap: 30px;
    grid-template-columns: 210px 1fr;
    margin-top: 16px;
    @media (max-width: $small-width) {
      grid-template-columns: 1fr;
    }
  }

  .search-list-input {
    position: relative;

    i {
      position: absolute;
      color: #4d4d4d;
      right: 0;
      padding: 5px;
    }
  }

  .categorii {
    height: 191px;
    overflow-y: scroll;
  }

  .h-0 {
    height: unset;
    overflow: hidden;
  }
}

.accordeon {
  li {
    list-style: none;
    border-bottom: 1px solid #a0a0a0;
    cursor: pointer;
  }
}


.card-1 {
  min-height: 132px;

  .card-body {
    @media (max-width: $large-width) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &:hover {
    .btn-brown {
      background-color: #4d4d4d;
      border: solid 2px rgba(181, 175, 175, .5);
      transition: all ease .3s;
    }
  }

  .btn-center {
    align-self: flex-end;
    padding-right: 40px;
    padding-left: 80px;
    padding-bottom: 21px;
    @media (max-width: $large-width) {
      padding-left: 15px;
      padding-right: 15px;
      align-self: start;
    }
  }

  img {
    @media (max-width: $tablet-width) {
      width: 100%;
    }
  }
}

.pagination-list {
  height: 30px;
  margin-top: 34px;
  padding-left: 15px;
  padding-right: 8px;
  background-color: #bfbfbf;
  @media (max-width: $small-width) {
    height: unset;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  select {
    width: 50px;
    height: 20px;
    font-size: 12px;
    margin-left: 7px;
    margin-right: 7px;
    padding-left: 2px;
    color: #4d4d4d;
  }

  .pagination-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 7px;

    .child {
      width: 20px;
      height: 20px;
      border: 1px solid #a0a0a0;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      color: #4d4d4d;
      border-radius: 4px;
      cursor: pointer;
    }

    .active-button {
      background-color: #966c20;
      color: $white;
    }
  }
}

.list-dropdown {
  border-radius: 3px;
  background: $white;
  top: 22px;
  z-index: 1;
  padding-top: 9px;
  padding-bottom: 6px;

  li {
    color: #4d4d4d;
    padding: 4px 9px;
    &:hover {
      color: $white;
      background: #956b1f;
      transition: all ease 0.3s;

    }
  }
}
