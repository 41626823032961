@import '../abstracts/variables';
.custom-control-label::before {
    top: 2px!important;
    width: 14px!important;
    height: 14px!important;
    border: solid 1px #a0a0a0!important;
    border-radius: 2.8px!important;
}

.custom-control-label::after {
    top: 1px!important;
    left: -25px!important;
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: #ac8e56!important;
    background-color: #ac8e56!important;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none!important;
}

.custom-control-input:active~.custom-control-label::before {
    background-color: #fff!important;
}

.input-search-checkbox {
    position: relative;
    height: 24px;
    border-radius: 3px;
    border: solid 1px #a0a0a0;
    background-color: #ffffff;
    color: #a0a0a0;
    &:focus {
        border: 1px solid $brownLight!important;
        border-radius: 3px;
        outline: none;
        i {
            color: $brownLight!important;
        }
    }
    &::placeholder {
        color: #a0a0a0;
    }
    &::-ms-input-placeholder {
        color: $white;
    }
    &::-ms-input-placeholder {
        color: $white;
    }
}

.ckeckboxes {
    height: 193px;
    overflow-y: scroll;
}

.append-search {
    i {
        position: absolute;
        text-align: 0;
        top: 0;
        right: 2px;
        font-size: 14px;
        padding: 6px;
        color: #4d4d4d;
        cursor: pointer;
    }
}

// /* width */
// ::-webkit-scrollbar {
//     width: 20px;
// }
// /* Track */
// ::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 5px grey;
//     border-radius: 10px;
// }
// /* Handle */
// ::-webkit-scrollbar-thumb {
//     background: red;
//     border-radius: 10px;
// }
// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//     background: #b30000;
// }