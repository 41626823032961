@import "./src/sass/abstracts/variables";
.s-7 {
    .font-25 {
        margin-top: 27px;
    }
    img {
        margin-top: 23px;
        margin-bottom: 30px;
    }
    .text-cut {
        margin-bottom: 46px;
        span {
            z-index: 12;
            position: relative;
            background: #f1f1f1;
            padding: 0 30px;
        }
        .line {
            height: 1px;
            background-color: #a0a0a0;
            position: relative;
            bottom: 14px;
            width: 100%;
            @media (max-width: $tablet-width) {
                display: none;
            }
        }
    }
}

.title-home {
    background: #d6d6d6;
}

.wrapper-1,
.s-4 {
    .uppercase {
        padding-top: 21px;
        padding-bottom: 21px;
        font-size: 18px;
    }
    .d-flex {
        margin-right: 31px;
        margin-left: 31px;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @media (max-width: $small-width) {
            justify-content: space-around;
        }
    }
    .mt-15 {
        margin-top: 15px;
    }
    button {
        width: 100px;
        height: 25px;
        font-size: 13px;
        margin-top: 13px;
        margin-bottom: 13px;
        border-radius: 10px;
        font-weight: 600;
    }
}

.divider-article {
    z-index: 12;
    margin-top: 19px;
    span {
        position: relative;
        z-index: 12;
    }
    .line {
        height: 1px;
        background-color: #fff;
        position: relative;
        bottom: 10px;
    }
}

// blog section
.blog {
    .bg-white {
        padding: 24px 25px 35px 15px;
        min-height: 200px;
        h6 {
            margin-bottom: 2em;
        }
        p {
            line-height: 1.5;
        }
    }
}

.img-gradient {
    border-radius: 50%;
    height: 90px;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(-40deg, #bca273 0, #f1d8a5 35%, #d4a66a 67%, #956b1f 100%);
}

.display-top {
    position: relative;
    right: -9px;
    bottom: 16px;
}

// section 3
.section3 {
    .list-group-item {
        padding: 15px 6px;
        border: solid 1px #a0a0a0;
    }
    .bg-dark {
        background: $blackGrey!important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .title {
            color: $white;
            font-size: 15px;
            font-weight: 600;
            span {
                text-transform: uppercase;
                color: $brownLight;
            }
        }
        .display-top {
            position: relative;
            right: -9px;
            bottom: 16px;
        }
        .image-gradient {
            margin-top: 1.5em;
            margin-bottom: .75em;
            background: red;
            border-radius: 50%;
            height: 90px;
            width: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: linear-gradient(-40deg, #bca273 0%, #f1d8a5 35%, #d4a66a 67%, #956b1f 100%);
        }
    }
    .bg-brown {
        span {
            background: $brownLight;
            position: relative;
            z-index: 1;
        }
        .line {
            height: 1px;
            background-color: #ffffff;
            position: relative;
            bottom: 12px;
        }
    }
    .bg-white {
        @media(min-width: $small-width) {
            border: solid 1px #a0a0a0;
        }
    }
    .footer-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        min-height: 90px;
        .title {
            font-size: 12px;
            font-weight: bold;
            padding: 1em .8em;
            text-transform: uppercase;
        }
        button {
            width: 100px;
            height: 25px;
            border-radius: 10px;
            border: solid 2px $brownLight;
            background-color: #966c20;
            font-size: 13px;
            font-weight: 600;
            color: #ffffff;
            padding: 0;
            &:focus {
                box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
            }
        }
    }
}

// section 4
.btn-orange {
    background-color: $orange!important;
    border: 2px solid rgba(255, 223, 176, 0.5)!important;
}

.image-instruments{
    width: 55px;
    top: 0px;
    left: 0;
}

.bg-image-contact {
    color: #313131;
    width:100%;
    padding: 50px 30px 40px 30px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 422px;
    margin-top: 50px;
    margin-bottom: 50px;
    border: 1px solid #818181;

    @media (max-width: $small-width) {
        margin-top: 0;
        margin-bottom: 30px;

    }
    &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(214,214,214,1);
    }
    .content-box {
        position: relative;
        z-index: 1;
        text-align: center;
        width: 100%;
        .title {
            margin-top: 0;
        }
        p {
            margin-top: 0;
            font-size: 18px;
            font-weight: 600;
        }

        span {
            display: block;
            margin-bottom: 20px;
            font-size: 20px;
            &.tel, &.fax {
                a {
                    color: #52aad0;
                    padding-left: 10px;
                }

            }
            &.email {
                font-size: 20px;
                a {
                    color: #313131;

                }
            }
            a {
                font-weight: 600;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.bg-white  {
    background-color: #ffffff;
}

.contact-form {

    label {
        display: block;
        width: 100%;
        font-family: Open Sans,sans-serif;
        font-size: 13px;
        font-weight: 700;
        line-height: 1.54;
        color: #ac8e56;
        margin-right: 30px;
        max-width: 210px;
    }
    input {
        border-radius: 3px;
        border: 1px solid #a0a0a0;
        background-color: #f1f1f1;
        padding: 4px 13px;
        height: 28px;
        width: 100%;
        font-size: 13px;
        font-weight: 600;
        color: #4d4d4d;
        &:focus {
            outline: 0;
            box-shadow: 0 0 10px 0 rgba(68,65,62,.2);
        }
    }
    textarea {
        width: 100%;
        height: 164px;
        border-radius: 3px;
        border: 1px solid #a0a0a0;
        background-color: #f1f1f1;
        padding: 4px 13px;
        font-family: Montserrat,sans-serif;
        font-size: 14px;
        resize: none;
        &:focus {
            outline: 0;
        }
    }
    .form-group {
        margin-bottom: 20px;
        .controls {
            width: 100%;
        }

    }
    form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .btn-grey {
        width: 150px;
        height: 28px;
        border: 1px solid #4d4d4d;
        background-color: #d6d6d6;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 9px;
        cursor: pointer;
        min-width: 120px;
        color: #4d4d4d;
        text-align: center;
        font-family: Open Sans,sans-serif;
        font-size: 13px;
        font-weight: 600;
        margin-left: auto;
        margin-right: auto;
        transition: 0.3s ease-in-out;
        &:hover,
        &:focus {
            border: 1px solid #4d4d4d;
            background-color: #303030;
            color: #fff;
        }

        i {
            font-size: 10px;
            margin-left: 5px;
        }
    }
    .w-50 {
        width: calc(50% - 8px);
        @media (max-width: $small-width) {
            width: 100%;
        }
    }

}
.error {
    input ,textarea{
        border: 1px solid #AE0701;
    }
}