@import '../abstracts/variables';

button {
  outline: none;
  cursor: pointer;
}

.btn-black {
  width: 130px;
  height: 37px;
  border-radius: 9.4px;
  background-color: #272727;
  border: 2px solid rgba(195, 195, 193, 0.5);
}

.btn-article {
  width: 100px;
  height: 26px;
  border-radius: 10px;
  border: solid 2px rgba(181, 175, 175, .5);
  background-color: #4d4d4d;
  outline: 0 !important;
  font-size: 13px;
  color: $white;
}

.btn-t {
  &:focus,
  &:hover {
    opacity: .9;
  }
}

.btn-orange {
  width: 130px;
  height: 37px;
  border-radius: 9.4px;
  background-color: #eda844;
}

.btn-brown {
  background: $brownLight;
  border: none;
  border-radius: 3.7px;
  width: 100px;
  height: 25px;
  border-radius: 10px;
  border: solid 2px #ac8e56;
  background-color: #966c20;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  padding: 0;
}

.grey-hover {
  &:hover {
    .btn-brown {
      background-color: #4d4d4d !important;
      border: solid 2px rgba(181, 175, 175, .5);
      transition: ease 0.3s;
    }
  }

}
