@import '../abstracts/variables';
.alphabet {
    div {
        width: 45px;
        height: 45px;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: .4em;
        color: #4d4d4d;
        font-weight: bold;
        font-size: 30px;
        background-color: #d2cfcd;
        cursor: pointer;
        &:hover {
            background-color: $brownLight;
            color: $white;
            transition: all ease .3s;
        }
    }
}