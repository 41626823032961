@import "../abstracts/variables";

.about {
  margin-top: 20px;
  margin-bottom: 75px;
  .d-grid {
    display: grid;
    grid-template-columns: 210px auto;
    grid-column-gap: 30px;
    @media (max-width: $small-width) {
      grid-template-columns: 1fr;
      grid-gap: 7px;
    }
  }

  .left-blocks {
    display: grid;
    grid-row-gap: 28px;
    grid-template-rows: 200px 500px 477px;
    @media (max-width: $small-width) {
      grid-template-rows: 200px 500px auto;
    }
  }
  .right-all {
    display: grid;
    grid-row-gap: 15px;
    grid-template-rows: 16px;
  }
  .right-blocks {
    display: grid;
    grid-row-gap: 18px;
    grid-template-rows: minmax(330px, auto) minmax(366px, auto) minmax(470px, auto);
  }

  .bloc-2 {
    min-height: 366px;
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: 1fr 1fr;
    @media (max-width: $large-width) {
      grid-template-columns: 1fr;
      grid-row-gap: 18px;
    }

.text-bloc-2{
  padding-bottom: 20px;
}
  }
  .text-block-1 {
    margin-top: 23px;
    padding-left: 29px;
    padding-right: 34px;
    @media (max-width: $tablet-width) {
      padding-left: 15px;
    }
  }

  .bloc-1 {
    min-height: 330px;
  }

  .bloc-3 {
    min-height: 470px;
    display: grid;
    grid-template-columns: 3.2fr 1fr;
    grid-column-gap: 16px;
    @media (max-width: $tablet-width) {
      grid-template-columns: 1fr;
      grid-row-gap: 18px;
    }
    .article{
      margin: 20px 16px 0px 18px;
      line-height: 1.5;
      h6{
        margin-bottom: 4px;
      }
      .btn-articles{
        margin: 20px 20px 20px;
        button{
          width: 100px;
          height: 25px;
          border-radius: 10px;
          border: solid 2px rgba(181,175,175,.5);
          background-color: #4d4d4d;
          outline: 0!important;
          font-size: 13px;
        }
     }
    }
    .margin-line{
      margin-right: 14px;
      margin-left: 14px;
    }
  }
}
p{
  margin-top: 18px;
  line-height: 1.38;
  padding-bottom: 15px;
}
.pb-0{
  padding-bottom: 0;
}
.persons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 16px;
  @media (max-width: 630px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: $small-width) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 18px;
  }
  @media (max-width: $mobile-width) {
    grid-template-columns: 1fr;
  }
.border-black{
  min-height: 178px;
  background-repeat:no-repeat;
  font-size: 25px;
  text-transform: uppercase;
  color: $white;
  display: flex;
  padding: 0 9px 8px 13px;
  background-position: bottom;
}
  .pers-1 {
    background-image: url('../img/img-person-1.png');
  }

  .pers-2 {
    background-image: url('../img/img-person-2.png');
  }

  .pers-3 {
    background-image: url('../img/img-person-3.png');

  }

  .pers-4 {
    background-image: url('../img/img-person-4.png');
  }
}
