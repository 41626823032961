@import "../abstracts/variables";
// @import "utils/fonts";
html,
body,
* {
  box-sizing: border-box;
  font-family: 'Open Sans', serif;
  margin: 0;
  padding: 0;
}


ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.wrapper {
  max-width: 1030px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.wrapper-1 {
  max-width: 1090px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.hidden {
  display: none;
}

i {
  cursor: pointer;
}

a {
  text-decoration: none;
  transition: .3s;

  &:visited {
    color: #313131;
  }
}

.px5 {
  padding-left: .5em;
  padding-right: .5em;
  color: #313131 !important;
}

p {
  line-height: 1.5;
}

img {
  max-width: 100%;
}

.cp {
  cursor: pointer;
}

ul {
  margin-bottom: 0 !important;
}

.i-none {
  @media (max-width: $mobile-width) {
    display: none !important;
  }
}

.z-index {
  z-index: 1;
}

.h-10 {
  height: 10px;
}

.line-height-1 {
  line-height: 1;
}

.rounded {
  border-radius: .25rem !important;
}

.lh-1-5 {
  line-height: 1.5;
}

.border-none {
  border: none;
}

.rounded-3 {
  border-radius: 3px;
}

.mr-13 {
  @media(min-width: 1000px) {
    margin-right: 13px;
  }
}

.f-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-60 {
  height: 59px;
}

.h-35 {
  height: 35px;
}


.w-sm-100 {
  @media (max-width: $large-width) {
    width: 100%;
  }
}

// grid system
.d-grid {
  display: grid;
}

.products-1 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 12px;
  flex-wrap: wrap;

  @media (max-width: 940px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: $small-width) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: $mobile-width) {
    grid-template-columns: 1fr;
  }
}

.s-2 {
  grid-template-columns: 210px 1fr;
  grid-gap: 30px;
  @media (max-width: $small-width) {
    grid-template-columns: 1fr;
  }
}

.s-3 {
  grid-template-columns: 210px auto;
  grid-gap: 30px;

  .row-up {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 16px;
    @media (max-width: $large-width) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 620px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: $mobile-width) {
      grid-template-columns: 1fr;
    }
  }

  .row-down {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 16px;
    @media (max-width: $large-width) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 620px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: $mobile-width) {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 620px) {
    grid-template-columns: 1fr;
  }
}

.block-grid {
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @media (max-width: $tablet-width) {
    grid-template-columns: 1fr;
  }
}

.hover-1 {
  overflow: hidden;


  .img {
    overflow: hidden;
  }
  .image-box {
    width: 100%;
    height: 150px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: 1.3s;
    overflow: hidden;
  }
  .h-150 {
    height: 150px;
    overflow: hidden;
  }

  img {
    transition: 1.3s;
  }

  &:hover {
    .image-box,
    img {
      transform: scale(1.1);
    }

    button {
      opacity: .9;
    }
  }
}

.underline {
  text-decoration: underline;
}

.breadcrumb-m {
  display: flex;
  padding-bottom: 4px;
  align-items: center;
  margin-bottom: -3px;

  .sign {
    margin-right: .3em;
    margin-left: .3em;
  }
}

.m-0 {
  margin: 0;
}

.px3 {
  padding-right: 2em;
  padding-left: 2em;
}


.hover-orange {
  &:hover {
    background: $orange !important;
    transition: all ease 0.3s;
  }
}


.green-checked {
  color: #31AC62 !important;
}

.u-select-none {
  user-select: none;
}

// border style
.border-black {
  border: solid 1px #a0a0a0 !important;
}

.border-bottom-1 {
  border-bottom: solid 1px #a0a0a0;
}

.border-right-1 {
  border-right: solid 1px #a0a0a0;
}

.border-right-lg-1 {
  @media (min-width: $large-width) {
    border-right: solid 1px #a0a0a0;
  }
}

.border-bottom-2 {
  border-bottom: 1px solid #dee2e6;
}

.border-bottom-2-sm {
  border-bottom: 1px solid #dee2e6;
  @media (min-width: $tablet-width) {
    border-bottom: none;
  }
}

// shadow style
.shadow-1 {
  box-shadow: 0 4px 8px rgba(0, 0, 0, .2), 0 3px 10px rgba(0, 0, 0, .2);
}

.f-600 {
  font-weight: 600;
}

.mt-0 {
  margin-top: 0 !important;
}

.orange-balloon {
  position: absolute;
  top: -11px;
  right: -11px;
  z-index: 100;
  width: 60px;
  height: 60px;
  background-color: #eda844;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hover-1 {
  position: relative;
  overflow: inherit;
}

.bg-brown {
  .d-grid {
    display: flex;
    flex-wrap: wrap;
  }
  .hover-1 {
    width: calc(25% - 12px);
    margin-left: 6px;
    margin-right: 6px;
    background-color: #ffffff;
    margin-bottom: 20px;
    @media (max-width: 991px) {
      width: calc(50% - 12px);
    }
    @media (max-width: 420px) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .products-1 {
    display: flex;
    flex-wrap: wrap;
  }

}


.line-height-0 {
  line-height: 0!important;
}
