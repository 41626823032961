
.checkbox  input{
  cursor: pointer;
}
.checkbox label {
  position: relative;
  padding-left: 5px;
  cursor: pointer;

}

.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 13px;
  height: 13px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #fff;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 13px;
  height: 13px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 2px;
  padding-top: 1px;
  font-size: 11px;
  color: #555555;
}

.checkbox input[type="checkbox"] {
  opacity: 0;
  z-index: 1;
}


.checkbox input[type="checkbox"]:checked + label::after {
  content: "";
  left: 4.2px;
  top: 2px;
  width: 2px;
  height: 6px;
  border: solid #313131;
  border-width: 0px 2px 2px 0px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


