@import "../abstracts/variables";

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.col-xs-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.col-xs-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.col-xs-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.col-xs-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%;
}

@media only screen and (min-width: $small-width) {
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-sm-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: $tablet-width) {
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-md-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: $large-width) {

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-lg-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: $extra-large-width) {

  .col-xl,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col-xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-xl-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
