.faq-page {
  margin-bottom: 77px;
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: 210px auto;
  @media (max-width: $small-width) {
    grid-template-columns: 1fr;
  }

  .left {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 28px;
    grid-template-rows: 200px 500px 477px;
    @media (max-width: $small-width) {
      grid-template-rows: 200px 500px auto;
    }
  }



  .right {
    display: grid;
    grid-row-gap: 14px;
    grid-template-rows: 17px;

    .right-down {
      display: grid;
      grid-row-gap: 31px;
      grid-template-rows: minmax(640px, auto) minmax(525px, auto);
      //grid-template-rows: 640px 530px;
      .row-up-faq {
        h1 {
          padding-bottom: 8px;
        }

        padding: 21px 21px 31px 20px;
        @media (max-width: $small-width) {
          padding: 21px 12px 31px 12px;

        }

        .block-faq {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: minmax(260px, auto) minmax(260px, auto);
          grid-column-gap: 15px;
          grid-row-gap: 16px;
          margin-top: 17px;
          @media (max-width: $tablet-width) {
            grid-template-columns: 1fr;
          }
          @media (max-width: $small-width) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 5px;
          }
          @media (max-width: $mobile-width) {
            grid-template-columns: 1fr;
          }

          .card-faq {
            padding-bottom: 21px;
          }

          .card-faq > * {
            padding-left: 12px;
            padding-right: 10px;
          }

          .text-card-faq {
            margin-bottom: 14px;
          }
        }
      }

      .row-down-faq {
        .font-25 {
          @media (max-width: $small-width) {
            font-size: 18px !important;
          }
        }
      }
    }
  }
}

