@import '../abstracts/variables';

.col-price {
  &:hover {
    .bg-black {
      background: $orange!important;
      transition: ease all .2s;
    }

    .bg-brown {
      background: $blackGrey;
      transition: ease all .2s;
    }

    button {
      background: $orange;
      color: $white;
      border-radius: 9.4px;
      border: 2px solid rgba(255, 223, 176, .5) !important;
      transition: ease all .2s;
    }
  }
}

.price {
  margin-top: 40px;

  .bg-white {
    padding-bottom: 13px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .price-text {
    padding: 0 110px;
    margin-top: 20px;
    @media(max-width: $tablet-width) {
      padding: 0;
    }
  }

  .grid-price {
    margin-top: 42px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: $tablet-width) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: $small-width) {
      grid-template-columns: 1fr;
    }

    .col-price {
      h2 {
        padding-top: 23px;
      }

      .price-block {
        margin-top: 31px;
        height: 40px;
      }

      .description-price {
        margin-top: 28px;

        li {
          padding-bottom: 16px;
          margin-top: 18px;
        }

      }

      .price-button {
        margin-top: 18px;
      }
    }

    .starter {
      @media (min-width: $small-width) and (max-width: $tablet-width){
        grid-column: 1/3;
      }
    }

    .regular {
      @media (min-width: $small-width) and (max-width: $tablet-width){
        grid-column: 3/6;
      }
    }

    .enterprise {
      @media (min-width: $small-width) and (max-width: $tablet-width){
        grid-column: 2/4;
      }
    }
  }
}

.price-title {
  .line-through {
    bottom: 28px;
    position: relative;
    border-bottom: 1px solid #a0a0a0;
    @media(max-width: $tablet-width) {
      display: none;
    }
  }

  span {
    padding: 1px 70px;
    @media(max-width: $tablet-width) {
      padding: 1px 40px;
    }
  }
}

.mt-90 {
  margin-top: 90px;
}

.line-bottom-13 {
  bottom: 13px !important;
}

.accordeon-m {
  margin-top: 37px;

  .d-flex {
    height: 35px;
    padding-left: 11px;
    padding-right: 10px;
    margin-bottom: 4px;
    @media (max-width: $tablet-width) {
      padding-left: 6px;
      font-size: 13px;
    }
    @media (max-width: $small-width) {
      font-weight: 400 !important;
    }

    img {
      padding-right: 8px;
    }
  }

  .font-14 {
    padding: 12px 20px 20px;

  }
}

.mt-48 {
  margin-top: 48px;
  margin-bottom: 33px;
}
