.product {
  margin-bottom: 75px;
  margin-top: 30px;

  .main-grid {
    display: grid;
    grid-template-columns: minmax(auto, 806px) 178px;
    grid-column-gap: 16px;
    @media (max-width: 830px) {
      grid-template-columns: 1fr;
    }

    .left-grid {
      display: grid;
      grid-row-gap: 15px;
      grid-template-rows: 36px minmax(764px, auto);

      .header-products {


        .head {
          height: 36px;
          padding-right: 9px;
          padding-left: 19px;

          .head-button {
            width: 150px;
            height: 25px;
            border-radius: 2.8px;
            background-color: #4d4d4d;
          }
        }
      }

      .produs-block {
        padding: 17px 15px 25px 15px;

        h1 {
          margin: 0 0 12px;
          line-height: 1;
        }

        .inside-products-block {
          display: grid;
          grid-column-gap: 18px;
          grid-template-columns: 370px auto;
          @media (max-width: 995px) {
            grid-template-columns: 320px auto;
          }
          @media (max-width: 635px) {
            grid-template-columns: 1fr;
            grid-row-gap: 20px;
            justify-content: center;
          }

          .left-inside {
            display: grid;
            grid-row-gap: 42px;
            grid-template-rows: minmax(auto, 390px) auto;
            @media (max-width: 635px) {
              justify-content: center;
            }

            .fixed-width {
              max-width: 370px;
              z-index: 0;
              @media (max-width: 995px) {
                max-width: 320px;
              }
              @media (max-width: 635px) {
                max-width: 290px;
              }
            }

            .icons-bottom {
              width: 80%;
              margin: 0 auto;

              h6 {
                letter-spacing: -0.3px;
                padding-bottom: 4px;
                width: 63%;
              }

              .mt-10 {
                .d-flex {
                  line-height: 2;
                }
              }

              .icons {
                display: grid;
                margin-top: 15px;
                width: 63%;
                grid-template-columns: repeat(5, 1fr);
                @media (max-width: $extra-large-width) {
                  width: 85%;
                }

                i {
                  width: 31px;
                  height: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: $brownLight;
                  border: solid 1px #ac8e56;

                  &:hover {
                    background: $brownLight;
                    transition: all ease 0.3s;
                    color: $white;
                  }
                }
              }
            }
          }

          .right-inside {
            .ul {
              padding-bottom: 7px;

              li {
                color: #a0a0a0;
                display: flex;
                font-size: 12px;
                align-items: center;
                letter-spacing: -0.3px;
                @media (max-width: $large-width) {
                  font-size: 11px;
                }

                i {
                  font-size: 14px;
                  padding-right: 5px;
                }
              }

              li:nth-child(2) {
                @media (max-width: $large-width) {
                  padding: 0 15px;
                }
              }
            }
          }

          .grid-inside {
            display: grid;
            grid-row-gap: 55px;
            grid-template-rows: minmax(270px, auto) minmax(320px, auto);
            margin-top: 19px;

            .down-inside {
              .header-tab {
                height: 50px;

                .tabs-item {
                  padding: 15px 15px;
                  cursor: pointer;
                }

                .middle-tab {
                  width: 43%;
                  @media (max-width: 995px) {
                    width: 100%;
                  }
                }

                .line {
                  width: 29%;
                  @media (max-width: 995px) {
                    display: none;
                  }
                }
              }

              .tab-info {
                display: grid;
                padding: 15px 17px;
                grid-template-columns: 31% 6% auto;
                min-height: 271px;

                .line {
                  border-right: 1px solid #a0a0a0;
                }

                .left-content,
                .right-content {
                  div {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 2;
                    color: #4d4d4d;
                  }
                }

                .right-content {
                  padding-left: 20px;
                }
              }
            }

            .right {
              border: solid 1px #a0a0a0;
              background-color: #f1f1f1;

              .head-kontact {
                height: 35px;
                background-color: #a0a0a0;
                margin-bottom: 19px;
                padding: 5px 16px 10px;
              }

              .body-kontact {
                padding-left: 8px;
                padding-right: 6px;

                i {
                  padding-right: 5px;
                }

                .d-flex {
                  line-height: 1.5;
                }

                .mb {
                  margin-bottom: 16px;
                }
              }

              .button-kontact {
                margin-top: 6px;
                padding-bottom: 9px;

                button {
                  height: 25px;
                  border-radius: 3.1px;
                  background-color: #4d4d4d;
                  border: none;
                  padding: 0 6px;
                }

                i {
                  padding-right: 17px;
                  color: $brownLight;
                }
              }
            }

            .left {
              .left-button {
                button {
                  width: 110px;
                  height: 28px;
                  background-color: #ac8e56;
                  border: none;
                  border-radius: 3.1px;
                  line-height: 1.2;
                }
              }

              ul {
                margin-top: 18px;

                li {
                  line-height: 1.93;
                }
              }

              .line-divider {
                width: 150px;
                height: 1px;
                background-color: #a0a0a0;
                margin-top: 7px;
                margin-bottom: 7px;
              }

              .mt-70 {
                margin-top: 50px;
                font-weight: 600;
                line-height: 1.83;
                color: #313131;
              }
            }

            .app-inside {
              display: grid;
              grid-template-columns: auto 180px;
              @media (max-width: 995px) {
                grid-template-columns: 1fr;
                grid-row-gap: 20px;
              }
            }
          }
        }
      }
    }

    .right-grid {
      display: grid;
      grid-row-gap: 20px;
      grid-template-rows: minmax(auto, 178px) minmax(auto, 350px) minmax(auto, 200px);


    }

    .swiper-button-prev {
      left: -4px !important;
    }

    .swiper-button-next {
      right: -25px !important;
    }

    .swiper-button-next,
    .swiper-button-prev {
      margin-top: -10px !important;
    }
  }

  .bottom-slider {
    height: 80px;
    width: 80%;
    margin: auto;
  }

  .swiper-container {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .swiper-slide-active {
    cursor: zoom-in;
  }

  .gallery-top {
    height: 80%;
    width: 100%;
  }

  .gallery-thumbs {
    height: 100%;
    box-sizing: border-box;
    padding: 10px 0;
  }

  .height-gallery-thumbs {
    height: 80px;
  }

  .gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }

  .gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1;
  }
}
.orange-block {
  padding-top: 24px;
  @media (max-width: 830px) {
    margin-top: 20px;
  }

  button {
    margin-top: 42px;
  }
}

.grey-block {
  background-color: #4d4d4d;
  padding-top: 15px;

  .button {
    margin-top: 15px;

    button {
      width: 100px;
      height: 25px;
      font-size: 13px;
      font-weight: 600;
      border-radius: 10px;
      border: solid 2px rgba(108, 87, 48, 0.5);
      background-color: #ac8e56;
    }
  }
}

.black-block {
  background-color: #272727;
  padding-top: 22px;
  letter-spacing: -0.6px;
}
.h-32 {
  height: 32px;
  font-weight: 600;
  line-height: 0.75;
  text-transform: uppercase;
}

#no-close-div {
  padding-left: 15px;
}

.product-dropdown {
  width: 178px;
  @media (max-width: $tablet-width) {
    width: 100%;
  }
}

#imageSet {
  display: none;
}

