.not-found {
    @media (max-width: 1000px) {
        padding-right: 30px;
        padding-left: 30px;
    }
    .height {
        height: 62.8vh;
    }
    .grid {
        display: grid;
        grid-template-columns: 434px 1fr;
        align-content: center;
        @media (min-width: 1000px) {
            margin-bottom: 350px;
        }
        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }
        .left {
            width: 367px;
            height: 340px;
            .upp-text {
                font-size: 140px;
                letter-spacing: -3.5px;
                color: #4d4d4d;
                font-weight: 300;
                line-height: 0.8;
                position: relative;
                left: -9px;
                margin-bottom: 10px;
            }
            .middle-text {
                color: #ac8e56;
                font-size: 210px;
                letter-spacing: -0.93px;
                font-weight: bold;
                line-height: 0.8;
            }
            .down-text {
                font-size: 37px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.93px;
                text-align: center;
                color: #4d4d4d;
            }
            @media (max-width: 768px) {
                text-align: center;
                width: 100%;
                margin-bottom: 3rem;
                height: 100%;
                .upp-text {
                    font-size: 100px;
                    left: 0;
                }
                .middle-text {
                    font-size: 100px;
                }
                .down-text {
                    font-size: 30px;
                }
            }
            @media (max-width: 576px) {
                text-align: center;
                width: 100%;
                margin-bottom: 3rem;
                height: 100%;
                .upp-text {
                    font-size: 70px;
                }
                .middle-text {
                    font-size: 60px;
                }
                .down-text {
                    font-size: 27px;
                }
            }
        }
        .upp-info {
            color: #4d4d4d;
            @media (max-width: 576px) {
                h6 {
                    font-size: 20px;
                }
                p {
                    font-size: 16px;
                }
            }
        }
        .down-input {
            input {
                height: 40px;
                border: solid 1px #d6d6d6;
                background-color: #ffffff;
                margin-right: 16px;
                width: 100%;
                padding-left: 1rem;
                &:focus {
                    outline: none;
                    border: 1px solid $brownLight
                }
                @media (max-width: 1000px) {
                    margin-right: 10px;
                }
            }
            button {
                height: 40px;
                width: 178px;
                background-color: #ac8e56;
                font-size: 20px;
                font-weight: bold;
                color: #f1f1f1;
                border: none;
                border-radius: 4px;
                &:hover {
                    background: #313131;
                }
            }
            @media (max-width: 576px) {
                button {
                    height: 30px;
                    font-size: 16px;
                    width: 138px;
                    font-size: 16px;
                }
                input {
                    height: 30px;
                    font-size: 16px;
                }
            }
        }
    }
}