  .head {
      font-size: 15px;
      justify-content: space-evenly;
      font-weight: bold;
      color: #fefefe;
      .img {
          position: relative;
          top: 17px;
          left: 0;
      }
  }
  
  .line-kontact {
      width: 170px;
      height: 1px;
      background-color: #a0a0a0;
  }
  
.h-200{
  height: 200px;
}
  
  .mw-210 {
      min-width: 210px;
  }

  .logos-btn{
    margin: 0 10px;
   button{
     margin-top: 10px;
     margin-bottom: 11px;
     height: 30px;
   }
  }
